$(function(){
    /* Main background */
    $('.main').vegas({
        slides: [{
            src: "videos/poster.jpg",
            video: {
                src: "videos/background.mp4"
            }
        }],
        overlay: 'imgs/overlays/05.png'
    });

    /* Mouse Fluid Scroll */
    var $window = $(window);
    var scrollTime = 0.5;
    var scrollDistance = 100;

    $window.on("mousewheel DOMMouseScroll", function(e){
        if(! $('.popup').hasClass('is-visible')) {
            $('html, body').stop();
            e.preventDefault();
            var delta = e.originalEvent.wheelDelta/120 || -e.originalEvent.detail/3;
            var scrollTop = $window.scrollTop();
            var finalScroll = scrollTop - parseInt(delta*scrollDistance);

            TweenMax.to($window, scrollTime, {
                scrollTo : { y: finalScroll, autoKill:true },
                ease: Power1.easeOut,
                overwrite: 5
            });
        }
    });

    /* Link To */
    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').stop().animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });
    });

    /* Popup */
    if($('section.info').length) {
        $(window).scroll(function () {
            if ($(this).scrollTop() > $('section.info').offset().top) {
                $('.pointer').addClass("fixed");
            } else {
                $('.pointer').removeClass("fixed");
            }
        });
    }
    $('.popup-trigger').on('click', function(e){
        e.preventDefault();
        $('.popup').addClass('is-visible');
    });
    $('.popup').on('click', function(e){
        if( $(e.target).is('.popup-close') || $(e.target).is('.popup') ) {
            e.preventDefault();
            $(this).removeClass('is-visible');
            $('.popup .video-wrapper').html('');
        }
    });
    $(document).keyup(function(e){
        if(e.which == '27'){
            $('.popup').removeClass('is-visible');
        }
    });

    /* Watch video */
    $('.watch-now').click(function(e) {
        e.preventDefault();
        if($(this).data('quality')) {
            var quality = $(this).data('quality');
        } else {
            var quality = '1080';
        }
        $.get('video', { item: $(this).attr('id'), quality: quality }).done(function(data) {
            $('.popup .video-wrapper').html(data);
            $('.popup').addClass('is-visible');
        });
    });

    /* Payment */
    $('.buy-all, .buy-now').click(function(e) {
        e.preventDefault();
        $.get('check_payment', { item: $(this).attr('id') }).done(function(data) {
            if(data == 'already_bought_item') {
                alert('Hai già comprato questo oggetto!');
            } else {
                $gotoUrl(PAY_URL, $.parseJSON(data));
            }
        }, "json");
    });

    function $gotoUrl(path, params, method) {
        //Null check
        method = method || "post"; // Set method to post by default if not specified.

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);

        //Fill the hidden form
        if (typeof params === 'string') {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", 'data');
            hiddenField.setAttribute("value", params);
            form.appendChild(hiddenField);
        }
        else {
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    if(typeof params[key] === 'object'){
                        hiddenField.setAttribute("value", JSON.stringify(params[key]));
                    }
                    else{
                        hiddenField.setAttribute("value", params[key]);
                    }
                    form.appendChild(hiddenField);
                }
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    var infoBar = document.querySelector(".cookies-infobar");
  var btnAccept = document.querySelector("#cookies-infobar-close");

  // Check if user has already accepted the notification
  if(wasAccepted()) {
    hideInfobar();
    return;
  }

  //listen for the click event on Accept button
  btnAccept.addEventListener("click", function (e) {
    e.preventDefault();
    hideInfobar();
    saveAcceptInCookies(7);
  });

  //hide cookie info bar
  function hideInfobar () {
    infoBar.className = infoBar.classList.value + " cookies-infobar_accepted";
  }

  // Check if user has already accepted the notification
  function wasAccepted () {
    return checkCookie() === "1";
  }

  // get cookie
  function checkCookie () {
    var name = "cookieInfoHidden=";
    var cookies = document.cookie.split(';');

    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0)==' ') {
          cookie = cookie.substring(1);
        }

        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
  };

  //save cookie
  function saveAcceptInCookies (daysOfValidity) {
    var now = new Date();
    var time = now.getTime() + (daysOfValidity * 24 * 60 * 60 * 1000);
    var newTime = new Date(now.setTime(time));
    
    newTime = newTime.toUTCString();
    
    document.cookie = "cookieInfoHidden=1; expires=" + newTime + "; path=/";
  }
});